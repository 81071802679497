import React, { useState, useEffect } from "react";
import OnneList from "../OnneList/OnneList";
import { Grid, Box } from '@mui/material';
import OnneListFilters from "../OnneList/OnneListFilters";
import { useDispatch } from 'react-redux';
import { setSelectedIndex } from "../../features/selectedIndexSlice";
import {useParams} from "react-router-dom";

const NAME = "Mapping";

export default function Mapping() {
    const {gtin} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSelectedIndex(NAME));
    }, []);

    let mainStyle = {
        paddingTop: "20px",
        height: "100%",
    };
    
    let filterStyle = {
        paddingLeft: "20px",
        paddingRight: "20px",
        height: "20%",
        maxHeight: "120px",
    };
    let listStyle = {
        paddingLeft: "20px",
        paddingRight: "20px",
        height: "82%",
    };

    return  <Box name="Mapping" height="calc(100% - 75px)" width="100%">
                <Grid xs={12} container height="100%" justifyContent="flex-start" alignItems="center" rowSpacing={1}>
                    <Grid style={filterStyle} item container xs={12}>
                        <OnneListFilters previewGtin={gtin} />
                    </Grid>
                    <Grid style={listStyle} item container xs={12}>
                        <OnneList gtin={gtin}/>
                    </Grid>
                </Grid>
            </Box>;
}
