import React, { useEffect } from "react";
import { Grid, Box } from '@mui/material';
import ReportsList from "./ReportsList";
import ReportsFilters from "./ReportsFilters";
import { useDispatch } from 'react-redux';
import { setSelectedIndex } from "../../features/selectedIndexSlice";

const NAME = "Reports";

export default function Reports() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSelectedIndex(NAME));
    }, []);

    let mainStyle = { marginTop: "20px" };
    let prodsStyle = { paddingLeft: "20px", paddingRight: "20px" };

    return  <Box name="Reports" height="100%" width="100%">
                <Grid xs={12} style={mainStyle} container direction="row" height={"100%"}>
                    <Grid style={prodsStyle} item container xs={12} height={"100%"}>
                        <ReportsList />
                    </Grid>
                </Grid>
            </Box>;
}
